<script>
import { Line } from "vue-chartjs";
import { mapGetters } from "vuex";
import i18n from "@/i18n";

export default {
  extends: Line,
  props: {
    dataSet: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    position: {
      type: String,
      default: "bottom"
    },
    unit: {
      type: String,
      default: "minute"
    },
    stepSize: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: "time"
    }
  },
  watch: {
    dataSet() {
      this.updateCustomChart();
    },
    labels() {
      this.updateCustomChart();
    }
  },
  mounted() {
    this.updateCustomChart();
  },
  computed: {
    ...mapGetters("Theme", {
      CURRENT_THEME: "GET_CURRENT_THEME"
    }),
    timeFormat() {
      const options = {
        en: {
          minute: "hh:mm a",
          hour: "YYYY-MM-DD hh:mm a",
          day: "YYYY-MM-DD"
        },
        de: {
          minute: "kk:mm",
          hour: "D.M hh:mm",
          day: "DD.MM.YYYY"
        }
      };
      return options[this.locale];
    }
  },
  methods: {
    updateCustomChart() {
      const vm = this;
      this.renderChart(
        {
          // labels: this.labels,
          datasets: [...this.dataSet]
        },
        {
          legend: {
            display: true,
            position: this.position,
            labels: {
              boxWidth: 16,
              boxHeight: 16,
              usePointStyle: true,
              fontColor: this.CURRENT_THEME === "dark" ? "#fafafa" : "#353C41"
            },
            onClick: function (e, legendItem) {
              const index = legendItem.datasetIndex;
              const labelHiddenState = legendItem.hidden;
              const meta = this.chart.getDatasetMeta(index);
              meta.hidden = !labelHiddenState;
              this.chart.update();

              vm.$emit("hidden-label", { labelIndex: index, state: meta.hidden });
            }
          },
          tooltips: {
            // enabled: true,
            mode: "index",
            position: "nearest",
            // Disable the on-canvas tooltip
            enabled: false,
            custom: function (tooltipModel) {
              // Tooltip Element
              let tooltipEl = document.querySelector("#custom-tooltip");

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "custom-tooltip";
                tooltipEl.classList.add("tooltip-container");
                tooltipEl.innerHTML = "<ul class='tooltip-list'></ul>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                let titleLines = tooltipModel.title || [];
                let bodyLines = tooltipModel.body.map(getBody);
                let innerHtml = [];
                titleLines.forEach(function (title) {
                  innerHtml.push(`<li class="tooltip-item tooltip-title">${vm.$d(new Date(title), "rep")}</li>`);
                });
                innerHtml;

                bodyLines.forEach(function (body, i) {
                  const [b] = [...body];

                  const f = b.split(":").map((l) => `<span>${l}</span>`);
                  let colors = tooltipModel.labelColors[i];
                  const s = `
                  background: ${colors.backgroundColor};
                  border-color: ${colors.borderColor};
                  `;

                  let span = `<span class="label-color" style="${s}"></span>`;
                  innerHtml.push(`<li class="tooltip-item">${span} ${f}</li>`);
                });

                let tableRoot = tooltipEl.querySelector(".tooltip-list");

                tableRoot.innerHTML = innerHtml.join(",").replaceAll(",", " ");
              }

              // `this` will be the overall tooltip
              let position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.zIndex = 100000;
              tooltipEl.style.position = "absolute";
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.pointerEvents = "none";
            }
          },

          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 70,
          tension: 0,
          // pased: false,
          // parsing: { xAxisKey: "datatime", yAxisKey: "value" }
          // scales: { x: { type: "linear" } }

          scales: {
            xAxes: [
              {
                type: this.type,
                time: {
                  displayFormats: this.timeFormat,
                  // {
                  // Check the language,format the time
                  // minute: "HH:MM",
                  // hour: "YYYY-MM-DD",
                  // day: "YYYY-MM-DD"
                  //day: "YYYY-MM"

                  // second: "HH:MM:SS",
                  // millisecond: "HH:MM:SS"
                  // },
                  // tooltipFormat: "YYYY-MM-DD HH:MM:SS:ssss",
                  stepSize: this.stepSize,
                  unit: this.unit
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  //suggestedMax: 100,
                  min: 0
                }
              }
            ]
          }
        }
      );
    }
  },
  data: () => ({
    locale: i18n.locale
  })
};
</script>
