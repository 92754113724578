
/**
 * Main project view and show all the schematic information of subproject
 * in the main project
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import MainProjectMapView from "@/components/mainProject/MainProjectMapView.vue";
import MainProjectSchematicView from "@/components/mainProject/MainProjectSchematicView.vue";
import MainProjectTabs from "@/components/mainProject/MainProjectTabs.vue";
import UserActions from "@/components/Users/Profile/UserActions.vue";
import Project from "@/model/Project";
import UnAuthorizedView from "@/components/shared/UnAuthorizedView.vue";
import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
import { mapActions, mapGetters, MapperForAction } from "vuex";

@Component({
  name: "MainProjectView",
  components: {
    MainProjectSchematicView,
    MainProjectTabs,
    MainProjectMapView,
    UnAuthorizedView
  },
  computed: {
    ...mapGetters("MainProject", {
      GETTER_MAIN_PROJECT_DETAILS: "GETTER_MAIN_PROJECT_DETAILS"
    })
  },
  methods: {
    ...mapActions("MainProject", {
      ACTION_GET_MAIN_PROJECT: "ACTION_GET_MAIN_PROJECT"
    })
  }
})
export default class MainProjectView extends Vue {
  ACTION_GET_MAIN_PROJECT!: MapperForAction;
  /*----------  Props  ----------*/
  readonly GETTER_MAIN_PROJECT_DETAILS!: Project;
  ACTION_STATUS: null | UserActions = null;
  intervalUpdateProjectData: Array<any> = [];

  IS_LOADING = false;
  $route!: Route;
  isAuthorized = false;

  async created() {
    try {
      this.IS_LOADING = true;
      await this.getProjectInformationEveryMinute();
      const res: any = await this.ACTION_GET_MAIN_PROJECT(this.$route.params.id as any);
      this.isAuthorized = true;
      if (res.status !== 200) {
        this.isAuthorized = false;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.IS_LOADING = false;
    }
  }

  private async clearAllIntervalsBeforeLeave() {
    console.log("######## Clear interval ######");
    let promises = this.intervalUpdateProjectData.map(async (intervalId: number) => {
      await clearInterval(intervalId);
    });
    await Promise.all(promises);
  }

  async getProjectInformationEveryMinute() {
    // Get information of the project  every minute from the back end

    const GET_PROJECT_DETAIL_EVERY_30_SECONDS = 30 * 1000;
    let newInterval = setInterval(async () => {
      console.log("### INTERVAL IS RUNNING #####");
      if (this.$route.params.id) {
        this.ACTION_GET_MAIN_PROJECT(this.$route.params.id as any);
      } else {
        await this.clearAllIntervalsBeforeLeave();
      }
    }, GET_PROJECT_DETAIL_EVERY_30_SECONDS);

    this.intervalUpdateProjectData.push(newInterval);
  }
}
