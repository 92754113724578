const formatDate = (item: string) => {
  try {
    const date = new Date(item);
    return new Intl.DateTimeFormat("en-GB").format(date);
  } catch (error) {
    const reg: RegExp = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g;
    const formatDate: RegExpMatchArray | null = item.match(reg);
    const date = formatDate !== null ? new Date(formatDate[0]) : new Date();
    return new Intl.DateTimeFormat("en-GB").format(date);
  }
};

export default formatDate;
