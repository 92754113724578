import { DEVICE_STATUS } from "@/model/ModelSection";

export const calculateDeviceStatus = (terminalValue: number = 0): "low" | "med" | "full" => {
  return terminalValue <= 24 ? "low" : terminalValue > 24 && terminalValue < 25 ? "med" : "full";
};
export const isTheScreenOff = (
  batteryStatus: string = "low",
  deviceStatus: DEVICE_STATUS = "ALERT",
  online: boolean = true
): boolean => {
  const isBatteryLow = batteryStatus === "low",
    isInAlertStatus = deviceStatus === "ALERT",
    isOffLine = !online;

  return isBatteryLow && isInAlertStatus && isOffLine;
};

export const getConnectionStrengthImageName = (terminalValue: number = 0): number => {
  switch (true) {
    case terminalValue === 0:
      return 0;
    case terminalValue < 5:
      return 1;
    case terminalValue >= 5 && terminalValue < 12:
      return 2;
    case terminalValue >= 12 && terminalValue < 17:
      return 3;
    case terminalValue >= 17 && terminalValue < 22:
      return 4;
    case terminalValue >= 22 && terminalValue < 27:
      return 5;
    default:
      return 6;
  }
};
